import {
  Animation,
  Color3,
  Color4,
  CubeTexture,
  FreeCamera,
  HemisphericLight,
  Mesh,
  MeshBuilder,
  PBRMaterial,
  Scene,
  StandardMaterial,
  Texture,
  Vector3,
} from "@babylonjs/core";
import React, { FunctionComponent } from "react";
import ReactDOM from "react-dom";
import BabylonJsScene from "./components/scene";

const Bla: FunctionComponent = (props) => {
  let box: Mesh;

  const onSceneReady = (scene: Scene) => {
    scene.clearColor = new Color4(0, 0, 0, 0);

    // This creates and positions a free camera (non-mesh)
    var camera = new FreeCamera("cam1", new Vector3(0, 2, -10), scene);
    // camera.fov = 2 * Math.PI * 0.3

    // This targets the camera to scene origin
    camera.setTarget(Vector3.Zero());

    // This attaches the camera to the canvas
    const canvas = scene.getEngine().getRenderingCanvas();
    camera.attachControl(canvas, true);

    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    var light = new HemisphericLight("light", new Vector3(5, 10, 0), scene);

    // Default intensity is 1. Let's dim the light a small amount
    // light.direction = new BABYLON.Vector3()
    light.intensity = 2.9;

    // Our built-in 'sphere' shape.

    const count = 50;
    const boxes = [];

    var skybox = MeshBuilder.CreateBox("skyBox", { size: 100.0 }, scene);
    var skyboxMaterial = new StandardMaterial("skyBox", scene);
    skyboxMaterial.backFaceCulling = false;
    skyboxMaterial.reflectionTexture = new CubeTexture(
      "textures/skybox",
      scene
    );
    skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
    skyboxMaterial.specularColor = new Color3(0, 0, 0);
    skybox.material = skyboxMaterial;

    // var hdrTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("textures/environment.dds", scene);
    const metal = new PBRMaterial("metal", scene);
    // metal.reflectionTexture = hdrTexture;
    metal.reflectionTexture = new CubeTexture("textures/skybox", scene);
    metal.microSurface = 0.96;
    metal.reflectivityColor = new Color3(0.85, 0.85, 0.85);
    metal.albedoColor = new Color3(0.71, 0.71, 0.71);

    for (var i = 0; i < count; i++) {
      var box = MeshBuilder.CreateBox(
        `box${i}`,
        {
          size: 0.7,
        },
        scene
      );
      // box.showBoundingBox = true;
      box.material = metal;
      box.position.x = (0 - count / 2 + i) * 1.6;
      boxes.push(box);
    }

    boxes.forEach((box, i) => {
      const anim = new Animation(
        "rotate-cube",
        "rotation",
        60,
        Animation.ANIMATIONTYPE_VECTOR3,
        Animation.ANIMATIONLOOPMODE_RELATIVE
      );
      const percent = i / count;
      const frames = [
        {
          frame: 0,
          value: new Vector3(
            2 * Math.PI * percent,
            2 * Math.PI * percent,
            2 * Math.PI * percent
          ),
        },
        {
          frame: 359,
          value: new Vector3(
            2 * Math.PI * (1 + percent),
            -2 * Math.PI * (1 + percent),
            2 * Math.PI * (1 + percent)
          ),
        },
      ];
      anim.setKeys(frames);

      box.animations.push(anim);
      scene.beginAnimation(box, 0, 359, true, 0.5);
    });

    return scene;
  };

  /**
   * Will run on every frame render.  We are spinning the box on y-axis.
   */
  const onRender = (scene: Scene) => {
    if (box !== undefined) {
      var deltaTimeInMillis = scene.getEngine().getDeltaTime();

      const rpm = 10;
      box.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
    }
  };

  return (
    <BabylonJsScene
      antialias
      onSceneReady={onSceneReady}
      onRender={onRender}
      style={{
        width: "100%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        padding: 0,
        display: "block",
      }}
    />
  );
};

var mountNode = document.getElementById("page");
ReactDOM.render(<Bla />, mountNode);
